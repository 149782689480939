import React, { useEffect } from "react";
import SectionPage from "../components/section-page";
import marked from "marked";
import { resetMetadata } from "../actions/metas";

const ContentfulPageContainer = ({ page, ...props }) => {
  useEffect(() => {
    resetMetadata();
  }, []);

  return (
    <SectionPage
      className="audio-setup-page"
      headerImageUrl={page.headerImage}
      headerText={page.headerText}
      html={marked(page.content)}
      shownInGettingStarted={page.showInGettingStarted}
    />
  );
};

export default ContentfulPageContainer;
